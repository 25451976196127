<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          关于我们
        </h1>
        <div class="article-body clearfix mb-3">
          <h1>关于使用微信推送新闻的通知</h1>
          <img
            :src="`${$cdn.resource(`gfx/tradewinds/2023_tw-wechats.png`)}`"
            class="w-100"
            alt="">
          <p>
            为了让我们的客户和读者更加方便阅读我们的新闻报道，本公司将从6月1日起通过社交媒体平台微信(WeChat)每日推送最新的新闻报道、行业动态、活动资讯、最新促销等信息。
          </p>
          <p>
            请关注我们的微信公众号，了解最新精彩内容。
          </p>
          <p>
            关注我们： 在微信内搜索公众号（TradeWinds《贸易风》海事周报）加公众微信号;
          </p>
          <p>
            或扫描二维码关注公众号:
          </p>
        </div>

        <div class="text-center">
          <img
            :src="`${$cdn.resource(`gfx/tradewinds/2023_tw-wechatQR.jpg`)}`"
            class="w-100"
            style="max-width: 300px"
            alt="">
        </div>

        <div class="article-body clearfix mb-3">
          <p>
            欲了解更多详情，请通过以下方式联系我们: 电话 +65 3165 0960, 电邮 <a href="mailto:sales@tradewindsnews.com">sales@tradewindsnews.com</a>
          </p>
          <p>
            请点击此处进行注册, 我们会给您发送我们认为您可能感兴趣的市场信息，包括《贸易风》的独家优惠、 出版刊物、展会、会议活动等信息。
          </p>

          <component
            :is="'script'"
            v-html="formScript"/>

          <hr>
          <h2>关于我们</h2>
          <p>
            当您需要最新的影响市场的重大消息时、当您希望了解对最具影响力的商家进行的大型访谈时、当您想借助最敏锐的眼光让您在激烈的竞争中捷足先登时，请使用海运行业最受瞩目、最优秀的新闻服务---《贸易风》。
          </p>
          <p>
            我们在全球的专业记者队伍通过报刊、网络随时为您提供最新的行业动态。
          </p>
          <p>
            自1990以来，我们以创新的方式报道海运新闻。今天，我们仍然是专业人士的必选资源。我们提供全面的海运新闻报道，涵盖油轮、散货船、集装箱船、液化天然气运输船、保险、事故、造船，金融、海工等，内容应有尽有。
          </p>
          <p>
            我们熟悉美国、英国、挪威、希腊、新加坡、中国、印度等各国市场上的风云人物，为您提供以市场为导向的新闻咨询。
          </p>
          <p>
            您可以随时随地通过应用程序得到我们的快讯，还有详尽的商业报告和我们独创的季刊TW+。
          </p>
          <p>
            海运永不眠，《贸易风》无与伦比的全天候报道不容错过！
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { EmbedMixin, AssetMixin } from 'global-mixin';
import { Page } from 'global-components';

export default {
  name: 'chinese-page',
  extends: Page,
  mixins: [EmbedMixin, AssetMixin],
  data() {
    return {
      formScript: ''
    };
  },
  embed(head) {
    head.script('//js.hsforms.net/forms/v2.js', {
      charset: 'utf-8'
    });
  },
  async assets() {
    this.formScript = `hbspt.forms.create({portalId: "1545457", formId: "6e114311-1032-4aa4-b908-2c0deaf2038a"});`;
    this.$nextTick();
  }
};
</script>
